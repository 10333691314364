import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { WebStorageService, SESSION_STORAGE } from 'angular-webstorage-service';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  userData: BehaviorSubject<any> = new BehaviorSubject('');

  public baseUrl = environment.baseUrl;
  public user: any;
  public Genredata: any ;

  constructor(
    @Inject(SESSION_STORAGE) public sessionStorages: WebStorageService,
    private http: HttpClient) {
    this.user = JSON.parse(localStorage.getItem('userData'));
  }

  getGenres() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.user.jwt
      })
    };
    return this.http.get(this.baseUrl + 'genres?appkey=' + this.user.appKey,
        httpOptions).pipe( map(
        res => {
            return res;
        },
        err => {
            return err;
        }
    ));
  }

  getGenreData(userStatus) {
    let finalUrl = this.baseUrl;
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.user.jwt
      })
    };

    if (userStatus) {
        finalUrl = this.baseUrl + 'users/' + this.user.userId + '/genres?appkey=' + this.user.appKey;
    }

    return this.http.get(finalUrl,
      httpOptions).pipe(map(
        res => {
            this.Genredata = res;
            return res;
        },
        err => {
            return err;
        }
    ));
  }

  getlanguages(){
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.user.jwt
      })
    };
    return this.http.get(this.baseUrl + 'languages?appkey=' + this.user.appKey,
        httpOptions).pipe( map(
        res => {
            return res;
        },
        err => {
            return err;
        }
    ));
  }

  getSelectedlanguages(userStatus) {
    let finalUrl = this.baseUrl;
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.user.jwt
      })
    };

    if (userStatus) {
        finalUrl = this.baseUrl + 'users/' + this.user.userId + '/languages?appkey=' + this.user.appKey;
    }

    return this.http.get(finalUrl,
      httpOptions).pipe(map(
        res => {
            this.Genredata = res;
            return res;
        },
        err => {
            return err;
        }
    ));
  }


  updateLanguagevalue(LangID) {
    let finalUrl = this.baseUrl;
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.user.jwt
      })
    };

      finalUrl = this.baseUrl + 'users/' + this.user.userId + '/languages';

    return this.http.put(finalUrl + '?appkey=' + this.user.appKey + '&ids=' + LangID,
      null
      , httpOptions).pipe(map(
        res => {
          return res;
        },
        err => {
          return err;
        }
      ));

  }

  updateGenreValue(GenreID) {
    let finalUrl = this.baseUrl;
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.user.jwt
      })
    };

      finalUrl = this.baseUrl + 'users/' + this.user.userId + '/genres?appkey=' + this.user.appKey + '&ids=' + GenreID;

    return this.http.put(finalUrl, null,
      httpOptions).pipe(map(
        res => {
          return res;
        },
        err => {
          return err;
        }
      ));
  }

  changeProfileImage(image){
    var requiredHeader= new HttpHeaders({
      'Authorization': this.user.jwt
    });
    const changeProfileImageURL=this.baseUrl+'users/'+this.user.userId+'/uploadpic?appkey='+this.user.appKey;
    return this.http.post(changeProfileImageURL,image,{headers: requiredHeader}).pipe(map(
      res =>{
        return res;
      },
      err =>{
        return err;
      }
    ));
  }

  getDeviceLists(){
    var requireHeader= new HttpHeaders({
      'Authorization': this.user.jwt
    });
    const getRokuDeviceListsURL= this.baseUrl+'users/'+this.user.userId+'/devices?appkey='+this.user.appKey;
    return this.http.get(getRokuDeviceListsURL, {headers: requireHeader});
  }

  unlinkRokuDeviceLists(){
    const unlinkRokuDeviceListsURL= this.baseUrl+'roku/'+this.user.userId+'/unlink';
    return this.http.get(unlinkRokuDeviceListsURL);
  }

  forceLogOutDevice(id, password){
    var forceLogOutURL= this.baseUrl+'users/'+this.user.userId+'/forcelogout/'+id+'?appkey='+this.user.appKey+'&password='+password;
    var forceLogoutHeader= new HttpHeaders({
      'Authorization': this.user.jwt
    });
    return this.http.get(forceLogOutURL,{headers: forceLogoutHeader});
  }

  updatePassword(existingPassword, newPassword, confirmPassword){
    var reqHeader= new HttpHeaders({
      'Authorization': this.user.jwt
    });
    const updatePasswordURL= this.baseUrl+'users/'+this.user.userId+'/update-password?appkey='+this.user.appKey+'&oldPassword='+existingPassword+'&newPassword='+newPassword+'&confirmPassword='+confirmPassword;
    return this.http.post(updatePasswordURL, null, {headers: reqHeader});
  }
  getBalanceUpdateRequest(){
        var reqHeader= new HttpHeaders({
      'Authorization': this.user.jwt
    });
    const updatePasswordURL= this.baseUrl+'users/'+ this.user.userId +'/balance' +'?appkey=' + this.user.appKey;
    return this.http.get(updatePasswordURL, {headers: reqHeader});
  }
}
